exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/Blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/Donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/JoinUs.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/Membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-projects-aid-for-living-life-js": () => import("./../../../src/pages/projects/AidForLivingLife.js" /* webpackChunkName: "component---src-pages-projects-aid-for-living-life-js" */),
  "component---src-pages-projects-canvas-js": () => import("./../../../src/pages/projects/Canvas.js" /* webpackChunkName: "component---src-pages-projects-canvas-js" */),
  "component---src-pages-projects-waiting-smile-js": () => import("./../../../src/pages/projects/WaitingSmile.js" /* webpackChunkName: "component---src-pages-projects-waiting-smile-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/Success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/Volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-templates-blogs-posts-js": () => import("./../../../src/templates/blogs-posts.js" /* webpackChunkName: "component---src-templates-blogs-posts-js" */),
  "component---src-templates-gallery-images-js": () => import("./../../../src/templates/gallery-images.js" /* webpackChunkName: "component---src-templates-gallery-images-js" */),
  "component---src-templates-news-posts-js": () => import("./../../../src/templates/news-posts.js" /* webpackChunkName: "component---src-templates-news-posts-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

